@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
	:root {
		--background: 0 0% 100% /* white */;
		--foreground: 229 84% 5% /* slate-950 */;
		--card: 0 0% 100% /* white */;
		--card-foreground: 229 84% 5% /* slate-950 */;
		--popover: 0 0% 100% /* white */;
		--popover-foreground: 229 84% 5% /* slate-950 */;
		--primary: 248 55% 27% /* hsl(248 55% 27%) */;
		--primary-foreground: 214 100% 97% /* blue-50 */;
		--secondary: 186 96% 91% /* hsl(186 40% 91%) */;
		--secondary-foreground: 197 64% 24% /* cyan-900 */;
		--muted: 210 41% 97% /* slate-100 */;
		--muted-foreground: 216 17% 47% /* slate-500 */;
		--accent: 269 100% 96% /* purple-100 */;
		--accent-foreground: 274 66% 32% /* purple-900 */;
		--destructive: 0 85% 61% /* red-500 */;
		--destructive-foreground: 211 41% 99% /* slate-50 */;
		--success: 141 85% 50% ; /* hsl(100 70% 60%) */
		--success-muted: 141 85% 85% ; /* hsl(141 85% 85%) */
		--success-foreground: 229 84% 5% ; /* hsl(229 84% 5% ) */
		--border: 215 32% 92% /* slate-200 */;
		--input: 215 32% 92% /* slate-200 */;
		--ring: 222 84% 54% /* blue-600 */;
		--radius: 0.5rem;
	}
  
	.dark {
		--background: 229 84% 5% /* slate-950 */;
		--foreground: 211 41% 99% /* slate-50 */;
		--card: 229 84% 5% /* slate-950 */;
		--card-foreground: 211 41% 99% /* slate-50 */;
		--popover: 229 84% 5% /* slate-950 */;
		--popover-foreground: 211 41% 99% /* slate-50 */;
		--primary: 222 84% 54% /* blue-600 */;
		--primary-foreground: 214 100% 97% /* blue-50 */;
		--secondary: 195 70% 28% /* cyan-800 */;
		--secondary-foreground: 184 100% 97% /* cyan-50 */;
		--muted: 218 33% 18% /* slate-800 */;
		--muted-foreground: 216 21% 66% /* slate-400 */;
		--accent: 273 68% 40% /* purple-800 */;
		--accent-foreground: 270 100% 99% /* purple-50 */;
		--destructive: 0 63% 31% /* red-900 */;
		--destructive-foreground: 211 41% 99% /* slate-50 */;
		--border: 218 33% 18% /* slate-800 */;
		--input: 218 33% 18% /* slate-800 */;
		--ring: 222 84% 54% /* blue-600 */;
	}
  }

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  
}



html {
	height: 100%;
}

body {
	font-family: "Poppins";
	height: 100%;
	margin: 0;
}

#root, .root {
	height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
