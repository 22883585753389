.home-container {
  width: 100%;
  height: 100%;
  .backdrop-content {
    width: 100%;
    position: relative;
    height: calc(100% - 60px);
  }
  .content {
    width: 90%;
    margin: auto;
    height: 100%;
  }
}
