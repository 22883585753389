.user-dialog {
  .label {
    width: 100%;
    margin-left: 32px !important;
  }

  .actions {
    align-items: baseline;
  }

  .button {
    width: 175px;
    height: 55px;
  }

  .large-input {
    width: 260px;
  }
  .small-input {
    width: 175px;
  }

  .location-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #0000001f;
    padding: 2px;
    width: 100%;
    height: 100%;
    min-height: 190px;
  }

  .full-width {
    width: 100%;
  }
  .location-button {
    height: 28px;
  }
}
