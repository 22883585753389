.signin-form-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .back-button-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;
  }

  .back-button-label {
    font-family: "Avenir-Medium" !important;
    margin-left: 8px !important;
    text-transform: uppercase !important;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    position: absolute;
    height: 80%;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    right: 15%;
    width: 360px;
  }

  .login-label {
    margin-bottom: 16px !important;
    text-align: center;
    width:"100%";
  }

  .input {
    margin-bottom: 6px !important;
  }

  .button {
    width: 148px;
    margin-bottom: 16px !important;
  }

  .forgot-password {
    font-family: "Avenir-Medium" !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    text-transform: none !important;
    margin-top: 8px !important;
    letter-spacing: 0px !important;
  }
}
